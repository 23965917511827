@import url("https://fonts.googleapis.com/css?family=News+Cycle&display=swap");
:root {
  font-size: 20px;
}

html,
body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  color: #606063;
  overflow: hidden;
  font-family: sans-serif;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.canvas {
  background: black;
}

.canvas > div {
  z-index: 1;
}

.loading {
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  pointer-events: none;
}

.img--primary {
  cursor: pointer;
  opacity: 0;
  object-fit: cover;
  max-width: 400px;
  width: 100%;
  height: auto;
}
